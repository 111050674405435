import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        generate: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('reports/attachments', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
    }
}
