<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6" md="3">
                        <label for="document_id">
                            Document Name <small class="text-danger" v-if="serverErrors && serverErrors.document_id">{{ serverErrors.document_id[0] }}</small>
                        </label>
                        <b-form-select
                            id="document_id"
                            name="document_id"
                            size="sm"
                            v-model="document_id"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="document in documents"
                                :key="document.id"
                                :value="document.id"
                            >
                                {{ document.title }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col cols="6" md="2">
                        <label for="number">
                            Shipment Number <small class="text-danger" v-if="serverErrors && serverErrors.number">{{ serverErrors.number[0] }}</small>
                        </label>
                        <b-form-input
                            id="number"
                            name="number"
                            size="sm"
                            v-model="number"
                        />
                    </b-col>
                    <b-col cols="6" md="2" class="pt-2">
                        <b-button
                            variant="outline-success"
                            size="sm"
                            @click="generateReport()"
                        >
                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Generate</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BBadge,
    } from 'bootstrap-vue'

    import store from '@/store'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import vSelect from 'vue-select'
    import attachmentsStoreModule from '@/views/cromis/reports/attachments/attachmentsStoreModule'
    import axios from '@axios'
    
    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
            BSpinner, BBadge,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context){
            const dataForm = ref(null)
            const myModal = ref(null)
            const saving = ref(false)
            const serverErrors = ref({
                document_id: null,
                number: null,
            })

            const documents = ref([])
            const document_id = ref(null)
            const number = ref(null)

            const CROMIS_STORE_MODULE_NAME = 'cromis-attachment'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, attachmentsStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                getInnerMenus(JSON.parse(localStorage.getItem('userData')).menus, context.root.$router.currentRoute.name)
            })

            const getInnerMenus = (menus, currentMenu) => {
                menus.map((menu) => {
                    if(menu.children.length > 0)
                        getInnerMenus(menu.children, currentMenu)

                    if(menu.webRoute && menu.webRoute.localeCompare(currentMenu) === 0)
                        documents.value = menu.children

                })
            }

            const generateReport = async () => {
                let hasError = false
                serverErrors.value = {
                    document_id: null,
                    number: null,
                }

                if(!document_id.value){
                    hasError = true
                    serverErrors.value.document_id = ['Specify document name']
                }

                if(!number.value){
                    hasError = true
                    serverErrors.value.number = ['Shipment number required']
                }

                if(!hasError){
                    saving.value = true

                    // await store.dispatch('cromis-attachment/generate', { documents: [document_id.value], number: number.value, recipients: [] })
                    //     .then(response => {
                    //         saving.value = false
                            
                    //     })
                    //     .catch(error => {
                    //         console.log('got error', error)
                    //     })
                    window.open(`${ axios.defaults.baseURL }/reports/attachments?report_id=${ document_id.value }&number=${ number.value }`, '_blank')
                    saving.value = false
                }
            }

            return {
                dataForm,
                myModal,
                saving,
                serverErrors,
                documents,
                document_id,
                number,
                generateReport,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>